<template>

  <div>

    <!-- Client Selector -->
    <client-selector
      v-if="$ability.can('manage', 'Statistique')"
      :selected-client-id.sync="selectedClientId"
      :can-client-be-nullable="true"
    />

    <!-- Table Container Card -->
    <b-card
      v-if="currentStats"
      no-body
      class="mb-0"
    >
      <div
        class="m-2 stats"
      >
        <feather-icon
          size="24"
          icon="TrendingUpIcon"
          class="mr-50"
          color="green"
        />
        <span>Moyenne du nombre de jours de stockage des véhicules </span><span class="font-weight-bold">(tronqué à {{ currentStats.pourcentageExclusion }}%): </span>
        <span class="font-weight-bold">{{ currentStats.moyenneNbJourStock }}</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <b-icon-circle-half
          class="mr-50"
          color="black"
        />
        <span>Demi-préparation: </span> <span class="font-weight-bold">{{ currentStats.pourcentageDemiPreparation }}%</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <b-icon-circle-fill
          class="mr-50"
          color="black"
        />
        <span>Préparation complète: </span> <span class="font-weight-bold">{{ currentStats.pourcentageCompletePreparation }}%</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <feather-icon
          size="24"
          icon="DropletIcon"
          class="mr-50"
          color="deepskyblue"
        />
        <span>Re-lavages effectués: </span> <span class="font-weight-bold">{{ currentStats.pourcentageRelavageEffectue }}%</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <feather-icon
          size="24"
          icon="ImageIcon"
          class="mr-50"
          color="brown"
        />
        <span>Véhicules avec photos: </span> <span class="font-weight-bold">{{ currentStats.pourcentagePhotoPrise }}%</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <feather-icon
          size="24"
          icon="ImageIcon"
          class="mr-50"
          color="brown"
        />
        <span>Lustrages effectués: </span> <span class="font-weight-bold">{{ currentStats.pourcentageLustrage }}%</span>
      </div>
      <div
        v-if="$ability.can('manage', 'Statistique')"
        class="m-2 stats"
      >
        <feather-icon
          size="24"
          icon="ImageIcon"
          class="mr-50"
          color="brown"
        />
        <span>Intersites: </span> <span class="font-weight-bold">{{ currentStats.pourcentageIntersite }}%</span>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BIconCircleHalf, BIconCircleFill,
} from 'bootstrap-vue'
import { watch } from '@vue/composition-api'
import ClientSelector from '@/views/site/sites-list/ClientSelector.vue'
import useStatistiquesList from '@/views/statistiques/statistiques-list/useStatistiquesList'
import { StorageManager, FILTER_CLIENT_KEY } from '../../../utils/StorageManager'

export default {
  components: {

    BCard,
    BIconCircleHalf,
    BIconCircleFill,
    // BCardBody,
    // BRow,
    // BCol,
    // BButton,
    // vSelect,
    ClientSelector,
  },
  mounted() {
    if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
      this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
    }
  },
  setup() {
    const {
      selectedClientId,
      currentStats,
    } = useStatistiquesList()

    watch(selectedClientId, val => {
      StorageManager.setFilter(FILTER_CLIENT_KEY, val)
    })

    return {
      selectedClientId,
      currentStats,
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.stats {
  font-size: 1.3em
}
</style>
