import store from '@/store'
import {
  ref, onMounted, watch,
} from '@vue/composition-api'
import ability from '@/libs/acl/ability'

export default function useStatistiquesList() {
  // DATA
  const selectedClientId = ref(null)
  const currentStats = ref(null)

  const fetchData = (clientId = null) => {
    if (!ability.can('manage', 'Statistique') && !ability.can('read', 'all')) {
      store.dispatch('statistique/fetchStatsFromFrontOfficeUser')
        .then(response => {
          currentStats.value = response.data
        })
    } else if (!clientId) {
      store.dispatch('statistique/fetchAllStats')
        .then(response => {
          currentStats.value = response.data
        })
    } else {
      store.dispatch('statistique/fetchStatsFromClient', clientId)
        .then(response => {
          currentStats.value = response.data
        })
    }
  }

  onMounted(() => {
    fetchData(selectedClientId.value)
  })

  watch([selectedClientId], () => {
    fetchData(selectedClientId.value)
  })
  return {
    selectedClientId,
    currentStats,
  }
}
